<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid }"
  >
    <p class="is-size-7 has-text-grey-icon">
      {{ prompt.name }}
      <b-tooltip
        class="ml-2"
        v-if="prompt.tooltip"
        :label="prompt.tooltip"
        type="is-tooltip"
        position="is-right"
      >
        <b-icon
          class="file-icon"
          icon-pack="fa"
          icon="info-circle"
          type="is-info"
        ></b-icon>
      </b-tooltip>
    </p>
    <b-field
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="errors"
    >
      <b-upload
        v-model="file"
        drag-drop
        expanded
        :disabled="disabled"
        accept="application/pdf, image/jpeg, image/png, image/tiff, image/heic, image/heif, image/avif, image/webp, image/bmp"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon v-if="!disabled" icon="upload" size="is-large"></b-icon>
              <b-icon
                v-else
                icon="spinner"
                size="is-large"
                class="spinner-rotate"
              ></b-icon>
            </p>
            <p>
              <strong>{{ prompt.label }}</strong>
            </p>
            <p>
              <i>{{ $t("af:upload.title") }}</i>
            </p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <p>
      <strong>{{ $t("af:upload.help.title") }} </strong>
      <span v-html="processLinks(prompt.help)"></span>
    </p>

    <slot></slot>
  </ValidationProvider>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
    },
    prompt: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    processLinks(text) {
      const regex = /\{\{([^|]+)\|([^}]+)\}\}/g;
      return text.replace(regex, '<a href="$1" target="_blank">$2</a>');
    },
    trimFileName(filename) {
      if (
        (filename.length < 25 && window.innerWidth < 768) ||
        filename.length < 50
      )
        return filename;
      return filename.slice(0, 25) + "...";
    },
  },
  watch: {
    // Handles internal model changes.
    async file(newVal) {
      if (newVal == null) return;

      this.$emit("documentUploadStart");
      try {
        await this.$store.dispatch(
          this.$route.meta.mortgage
            ? "mortgageApplication/uploadFile"
            : "loanApplication/uploadFile",
          {
            file: this.file,
            promptId: this.prompt.id,
            promptIdentifier: this.prompt.identifier,
            documentName: this.prompt.name,
          }
        );
        await this.$emit("input", newVal);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:file.upload.failed"),
          position: "is-bottom",
          type: "is-danger",
        });
      }

      await this.$emit("documentUploadFinish");
      this.file = null;
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.file = this.value;
    }
  },
};
</script>

<style scoped lang="scss">
.spinner-rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
